import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'button' ]

  jump(e) {
    let nextElement
    let inputs = this.inputTargets
    let currentIndex = inputs.findIndex(target => target == e.currentTarget)

    if (e.keyCode == 8) {
      e.currentTarget.value = ''
      nextElement = inputs[currentIndex - 1]  
      if (nextElement) {
        nextElement.focus();
        nextElement.value = '';
      }
    } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)){
      nextElement = inputs[currentIndex + 1]
      if (nextElement) {
        nextElement.value = ''
        nextElement.focus();
      } else {
        this.buttonTarget.click();
        this.buttonTarget.disabled = true;
      }
    } else {
      e.currentTarget.value = ''
    }
  }
}

