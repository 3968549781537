// This should either be moved to stimulus
// Either transformed into a simple entry file

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
const context = require.context('../devise/controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

// Close the notifications
const closeCross = document.querySelectorAll('.close')
closeCross.forEach((cross) => {
    cross.addEventListener('click', () => {
        cross.parentElement.classList.add('hide-animation')
        setTimeout(() =>{ cross.parentElement.style.display = 'none' }, 1000)
    })
})

// Trigger and handle the file uploads
const fileUploads = document.querySelectorAll('.fileupload')
fileUploads.forEach((inputContainer) => {
    const inputField = inputContainer.querySelector('input')
    const imgField   = inputContainer.querySelector('img')
    const label      = inputContainer.querySelector('label')

    // Trigger click when clicking the box
    if (imgField) {
        imgField.addEventListener('click', () => { inputField.click() })
    }

    // Display the image or the name of the file if file uploaded
    inputField.addEventListener('change', () => {
        if (imgField) {
            imgField.src = window.URL.createObjectURL(inputField.files[0])
            imgField.style.display = 'block'
            label.innerHTML = `<i class="fa fa-check"></i> ${inputField.files[0].name}`
        } else {
            label.innerHTML = `<i class="fa fa-check"></i> ${inputField.files[0].name}`
        }
        label.classList.add('label-valid')
    })
})

export const disableSubmitBtn = (form, submit, type) => {
  form.addEventListener('submit', (e) => {
    submit.disabled = true
    submit.style.paddingLeft = '20px'
    if (type === 'input') { submit.value = submit.dataset.sending }
    else { submit.innerText = submit.dataset.sending }
  })
}

const accountForm = document.querySelector('#account-form')
if (accountForm) {
  const accountSubmitBtn = accountForm.querySelector('button[type=submit')
  disableSubmitBtn(accountForm, accountSubmitBtn, 'button')
}

const passwordForm = document.querySelector('#edit_user')
if (passwordForm) {
  const passwordSubmitBtn = passwordForm.querySelector('input[type=submit')
  disableSubmitBtn(passwordForm, passwordSubmitBtn, 'input')
}